@import "../../../sass/variabels";
.breadcrumb {
  margin-bottom: 30px;
  text-align: start;
  font-size: 0.7rem;
  .break {
    margin: 0px 2px;
  }
  a {
    color: $maincolor;
  }
}
@import "./ToDesktop"