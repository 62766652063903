@import "../../../sass/variabels" , "../../../sass/components";
.Meeting-container{
    background-color: white !important;
    border: 1px solid $borderMeeting;
    border-radius: 4px;
    padding: 18px;
    max-height: 50px;
    overflow: hidden;
    transition: all .5s ease-in-out;
    margin: 5px 0px;
    .Meeting-information{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        .information{
            width: 60%;
            p{
                font-size: 0.8rem;
                margin: 0;
                font-weight: 500;
            }
        }
        .more{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            button{
                @extend %meetingBtn;
                svg{
                    fill: $maincolor;
                    font-size: 1.1rem;
                    display: block;    
                }
            }
            ul{
                width: 145px;
                position: absolute;
                top: 75%;
                right: 0px;
                list-style: none;
                background-color: white;
                border: 1px solid $maincolor;
                box-sizing: border-box;
                margin: 0;
                padding: 0;
                border-radius: 5px 0px 5px 5px;
                &::before{
                    content: "";
                    width: 33.59px;
                    position: absolute;
                    top: -2px;
                    right: 0;
                    height: 2px;
                    background-color: white;
                    z-index: 0;
                }
                li{
                    font-size: 0.7rem;;
                    color: $maincolor;
                    padding: 8px;
                    cursor: pointer;
                    &:hover{
                        color: white;
                        background-color: $maincolor;
                    }
                }
                li:not(:last-child){
                    border-bottom: 1px solid $maincolor;
                }
            }
        }
    }
    .checkin{
        margin-bottom: 10px;
        button{
            @extend %meetingBtn;
            width: 100%;
            padding: 15px;
            font-weight: 800;
            color: $maincolor;
        }
    }
}
.mx-150{
    max-height: 150px !important;
}
@import "./ToDesktop"