@import "../../../sass/variabels", "../../../sass/components";
.bookings {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: fit-content;
  gap: 20px;
}
.load {
  @extend %button;
  width: fit-content !important;
}
.choose {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  align-items: center;
  justify-content: center;
  .option {
    padding: 5px;
    width: 100px;
    height: 100px;
    background-color: $maincolor;
    text-align: center;
    margin: 5px;
    text-decoration: none;
    display: flex;
    span {
      margin: auto;
      color: white;
      text-decoration: none;
    }
  }
}
