@import "../../../sass/variabels", "../../../sass/components";
.confirmation {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 46px;
  left: 0;
  height: calc(100vh - 46px);
  overflow-y: auto;
  background-color: white;
  z-index: 1;
  @extend %scrollbar;
  .Date {
    width: 40px !important;
    height: 43px !important;
    .day {
      padding: 1px !important;
      span {
        font-size: 1rem !important;
      }
    }
  }
  h3 {
    color: green;
    font-size: 1.2rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .details {
    p {
      margin: 5px;
      color: $imperial;
    }
  }
  form {
    .input {
      @extend %formSection;
    }
    .radio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .one-radio {
        display: flex;
        gap: 15px;
        label {
          font-weight: 300;
          color: black;
        }
      }
    }
    .error {
      padding: 10px;
      margin: 5px;
      box-sizing: border-box;
      border-radius: 2px;
      border: 0.5px solid #f5c6cb;
      color: #721c24;
      background-color: #f8d7da;
    }
  }
  .formSection,
  .select-container {
    padding: 15px;
    @extend %formSection;
    .labelTitle {
      margin-bottom: 5px;
    }
  }
  .radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .one-radio {
      display: flex;
      gap: 15px;
      label {
        font-weight: 300;
        color: black;
      }
    }
  }
  .date {
    display: flex;
    justify-content: center;
  }
  .edit-search {
    padding: 10px;
    background-color: $secondcolor;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    button {
      justify-content: flex-start;
      gap: 20px;
      margin: 5px 0px !important;
      font-size: 1.1rem;
      @extend %anchor;
    }
  }
  .parent {
    padding: 15px;
    box-sizing: border-box;
    overflow: auto;
    max-height: 650px;
    .attendes {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      max-height: 300px;
      padding: 20px;
      overflow-y: auto;
      p {
        color: $imperial;
        font-weight: 400;
        margin: 5px;
      }
    }
  }
  .confirmationMessage {
    width: 80%;
    margin: 8px auto;
    font-weight: 300;
  }
  .deleteConfirmation {
    box-sizing: border-box;
    padding: 5px;
    background-color: $deletelight;
    p,
    span {
      color: $deleteDark;
    }
    p {
      font-size: 1.5rem;
      margin: 5px;
    }
  }
  .btns {
    display: flex;
    padding: 0px 20px;
    margin: 10px 0px;
    button {
      margin-bottom: unset !important;
      margin-top: 10px !important;
    }
    .return {
      @extend %button;
    }
    .deleteFill {
      background-color: $deleteColor;
      @extend %button;
    }
    .cancel {
      width: 100%;
      color: $maincolor;
      padding: 15px !important;
      @extend %meetingBtn;
    }
    .delete {
      width: 100%;
      color: $deleteColor;
      padding: 15px !important;
      border: 1px solid $deleteColor !important;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      @extend %meetingBtn;
    }
  }
}
@import "./ToDesktop";
