@import "../../../sass/variabels", "../../../sass/components";

.expanded {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  height: calc(100vh - 46px);
  overflow-y: auto;
  background-color: white;
  z-index: 3;
  transition: height 0.2s ease-in-out;
  .head {
    padding: 5px 15px;
    box-sizing: border-box;
    .title {
      color: $imperial;
      font-weight: bold;
    }
    .col-exap {
      button {
        color: $maincolor;
        margin: 0;
        font-size: 1.1rem;
        @extend %anchor;
        svg {
          font-size: 1.5rem;
        }
      }
    }
    div {
      flex: 1;
    }
  }
  .close {
    padding: 15px;
    box-sizing: border-box;
    button {
      font-size: 1rem;
      padding: 15px !important;
      width: 100%;
      color: $maincolor;
      @extend %meetingBtn;
    }
  }
  .bookable-specification {
    padding: 0px 15px;
    .specification {
      justify-content: center !important;
      margin: 5px 0px;
      svg {
        color: $imperial;
      }
      span {
        font-weight: 300;
      }
    }
  }
  .section {
    padding: 15px;
    p {
      text-align: left;
      color: $imperial;
      margin: 5px 0px;
    }
    span {
      display: block;
      text-align: left;
      font-size: 0.8rem;
      font-weight: 300;
    }
    .user {
      align-items: center !important;
      margin: 5px 0px;
      span:first-of-type {
        padding: 5px;
        width: 15px;
        height: 15px;
        font-weight: bold;
        font-size: 0.7rem;
        display: block;
        text-align: center;
        line-height: 15px;
        border-radius: 50%;
        background-color: $maincolor;
        color: white;
      }
    }
  }
  .formSection,
  .select-container {
    padding: 15px;
    @extend %formSection;
    .labelTitle {
      margin-bottom: 5px;
    }
  }
  .radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .one-radio {
      display: flex;
      gap: 15px;
      label {
        font-weight: 300;
        color: black;
      }
    }
  }
}
.collapsed {
  height: fit-content !important;
  overflow-y: hidden !important;
}

@import "./ToDesktop";
