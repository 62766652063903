@import "../../../sass/variabels";
.attendes-container {
  padding: 15px;
  p {
    text-align: left;
    color: $imperial;
    margin: 5px 0px;
  }
  span {
    display: block;
    text-align: left;
    font-size: 0.8rem;
    font-weight: 300;
  }
  .user {
    align-items: center !important;
    margin: 8px 0px;
    span:first-of-type {
      padding: 5px;
      width: 15px;
      height: 15px;
      font-weight: bold;
      font-size: 0.7rem;
      display: block;
      text-align: center;
      line-height: 15px;
      border-radius: 50%;
      background-color: $maincolor;
      color: white;
    }
  }
}
