@import "../../../../sass/variabels" , "../../../../sass/components";
.notify{
    padding: 20px;
    justify-content: flex-start !important;
    font-weight: bold;
    position: relative;
    text-align: start;
    &::before{
        content: "";
        width: 3px;
        height: 80%;
        border-radius: 5px;
        position: absolute;
        top: 50%;
        left: 2px;
        transform: translateY(-50%);
    }    
}

.available{
    background-color: #F1F9F4;
    color: #18562E;
    svg{
        fill: #29B059;
        font-size: 1.2rem;
    }
    &::before{
        background-color: #29B059;
    }
}
.not-available{
    background-color: #FFEFDF;
    color: #9F3F01;
    svg{
        fill: #F88618;
        font-size: 1.2rem;
    }
    &::before{
        background-color: #F88618;
    }
    .booked_times{
        margin: 10px 0px;
        display: flex;
        flex-wrap: wrap;
        .time{
            @extend %meetingBtn;
            color: $maincolor !important;
            font-weight: 500 !important;
            font-size: 0.7rem;
            margin: 5px;
        }
    }
}