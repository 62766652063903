$maincolor: #1376b6;
$lightmaincolor: #e5ebee;
$sidedown: #eef0f2;
$secondcolor: #f7f7f7;
$disabledmaincolor: #8fb9d4;
$natural: #444b53;
$imperial: #083c60;
$borderMeeting: #9ec7f0;
$deleteColor: #bf4040;
$deletelight: #ffebeb;
$deleteDark: #772828;
$toDesktop: "screen and (min-width:768px)";
$Tablet: "screen and (min-width:768px)";
$miniDesktop: "screen and (min-width:992px)";
$Desktop: "screen and (min-width:1200px)";
