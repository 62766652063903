@import "../../../sass/variabels", "../../../sass/components";
main {
  .wrapper {
    height: 100%;
    position: relative;
    .content {
      height: 100%;
      background-color: white;
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      padding: 8px;
      h2 {
        text-align: center;
      }
      .dashboard-div {
        padding: 8px;
        margin: 5px 0px;
        background-color: $secondcolor;
        border-radius: 4px;
        a {
          @extend %anchor;
        }
      }
      .actions {
        h3 {
          margin: 0;
        }
        button,
        .newbooking {
          @extend %button;
        }
      }
    }
  }
}
@import "./ToDesktop";
