aside .menu ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-inline-start: 0px;
}
aside .menu ul h2 {
  padding-inline-start: 20px;
  color: #083c60;
  font-size: 1rem;
}
aside .menu ul li {
  padding: 10px;
  color: #444b53;
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
aside .menu ul li:hover {
  background-color: #e5ebee;
}
aside .menu ul li svg {
  font-size: 1.3rem;
}

aside {
  height: 100%;
  background-color: #e5ebee;
  position: fixed;
  width: 56px;
  display: none;
  transition: all 0.5s ease-in-out;
  z-index: 4;
}
aside .collapse {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
aside .collapse button {
  color: #444b53;
  width: 100px;
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  transition: all 0.1s ease-in-out;
  transform: rotateY(180deg);
  display: flex;
  gap: 10px;
  cursor: pointer;
}
aside .collapse button img {
  width: 20px;
}
aside .menu {
  animation: opacityAnimation 1s ease-in-out;
}

.w-250 {
  width: 250px !important;
}

.rotate {
  transform: rotateY(0deg) !important;
}

.w-20p {
  width: 20% !important;
}

@keyframes opacityAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (min-width: 768px) {
  aside {
    display: block;
  }
}

