header {
  @media #{$Tablet} {
    height: 60px;
  }
  .slideDown {
    @media #{$Tablet} {
      width: 375px;
      top: 60px;
    }
  }
  .logo {
    @media #{$Tablet} {
      justify-content: flex-start;
      padding-inline-start: 20px;
      flex: unset;
      width: 40%;
    }
    @media #{$Desktop} {
      width: 70%;
    }
  }
  .info {
    @media #{$Tablet} {
      flex: 0.5;
    }
}
  div[role="input"] {
    @media #{$Tablet} {
      flex: 1;
      padding: 10px;
      margin: 5px;
    }
    @media #{$Tablet} {
      ul {
        background-color: white;
        border: 1px solid #b3b3b3;
        display: none;
        width: 100%;
        padding: 15px 0px;
        list-style: none;
        margin-top: 8px;
        li {
          margin: 5px;
        }
      }
    }
  }
  .Screenheight {
    @media #{$Tablet} {
      height: calc(100vh - 60px) !important;
    }
  }
}
