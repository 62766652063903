.check-container {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    margin: 5px 0px;
    .check {
        width: 45px;
        height: 20px;
        border: 2px solid #1376b6;
        border-radius: 25px;
        position: relative;
        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 2px;
            color: white;
          }
          .circle {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #1376b6;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            left: 5px;
            transition: all 0.2s ease-in-out;
          }
      }
      p {
        font-weight: 300;
        margin: 0;
      }
  }
  .left-95 {
    left: 25px !important;
  }
  .checked {
    background-color: #1376b6 !important;
  }
  .checked .circle {
    background-color: white !important;
  }