@import "../../../../sass/variabels", "../../../../sass/components";
.parent {
  .tabs {
    width: 100%;
    border: 1px solid $maincolor;
    border-radius: 3px;
    height: 65px;
    box-sizing: border-box;
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      list-style: none;
      gap: 5px;
      margin: 0;
      padding: 2px;
      box-sizing: border-box;
      position: relative;
      &::before {
        content: "";
        width: 2px;
        height: 90%;
        background-color: $maincolor;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      li {
        flex: 1;
        color: $maincolor;
        border-radius: 3px;
        cursor: pointer;
      }
      li.active {
        background-color: $maincolor !important;
        color: white;
      }
    }
  }
  .view {
    margin-top: 8px;
  }
  .mapview {
    text-align: center;
    h4 {
      color: $imperial;
    }
    .guiding {
      flex-wrap: wrap;
      padding-left: 5px;
      .dot-container {
        margin: 2px;
        .dot {
          width: 15px;
          height: 15px;
          border-radius: 50%;
        }
        .label {
          font-weight: 400;
        }
      }
    }
    .img-wrapper-in-search {
      overflow: auto;
      padding: 5px;
    }
    .floorplan-container {
      width: 100%;
      border: 2px solid $imperial;
      border-radius: 3px;
      margin: 8px auto;
      box-sizing: border-box;
      position: relative;
      display: flex;
      transition: transform 0.3s ease-in;
      transform-origin: top left;
      .floorplan-actions {
        flex: 1;
      }
      .floorplan-content {
        flex: 3;
        padding: 10px;
        box-sizing: border-box;
        overflow: auto;
        height: fit-content;
        .floorplan-plan {
          position: relative;
          transform: rotate(90deg) scale(1.3);
          height: fit-content;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .listview {
    .Bookable-container {
      .Bookable:first-of-type {
        margin-top: 10px;
      }
      .Bookable {
        padding: 8px;
        border: 1px solid $maincolor;
        border-radius: 3px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        margin-top: 5px;
        gap: 5px;
        .icon {
          flex: 1;
          svg {
            font-size: 2rem;
            color: $imperial;
          }
        }
        .info {
          flex: 5;
          color: $imperial;
          span {
            font-weight: 200;
            font-size: 0.7rem;
          }
        }
        .book {
          flex: 2;
          button {
            color: $maincolor;
            @extend %meetingBtn;
            &:hover {
              color: white !important;
              background-color: $maincolor !important;
            }
          }
        }
      }
    }
  }
}
.selecteddot {
  background-color: $maincolor;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    top: -3px;
    left: -3px;
    border: $maincolor 2px solid;
  }
}
.selecteddotmobile {
  &::after {
    content: "";
    width: 9px !important;
    height: 9px !important;
  }
}
.availabledot {
  background-color: #63a0dd;
}
.unavailabledot {
  background-color: #babdc1;
  cursor: not-allowed !important;
}
.m25 {
  margin-bottom: calc(100vh - 80vh);
}

@import "./ToDesktop";
