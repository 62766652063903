@import "../../../sass/variabels", "../../../sass/components";
.container{
  @extend %container;
.wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: auto;
  .logo,.gray,p,form {
    margin-top: 15px;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 10rem;
    }
  }
  .gray {
    background-color: $secondcolor;
    width: 100%;
    height: 150px;
  }
  .login-par {
    font-size: 1.5rem;

  }
  form {
    @extend %form;
  }
}
}
@import "./toDesktop"