.container{
    @extend %container;
  .wrapper {
    @media #{$toDesktop} {
      flex-direction: row;
      padding: 0;
     }
    .leftSide{
      @media #{$toDesktop} {
        position: relative;
        flex: 3;
        height: 100vh;
       }
    }
    .rightSide{
      @media #{$toDesktop} {
        padding: 45px;
        flex: 6;
       }
    }
    .logo {
      img {
        @media #{$toDesktop} {
          width: 7rem;
         }
      }
      @media #{$toDesktop} {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 20px;
        margin: unset;
       }
    }
    .gray {
      @media #{$toDesktop} {
        position: absolute;
        z-index: 2;
        height: 100%;
        top: 0;
        left: 0;
        margin: unset;
        background-image:linear-gradient(rgba(255,255,255,0.5),rgba(0,0,0,0.8)),url("https://imperialit.co.uk/images/service-page-bg.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
       }
    }
    .login-par {
      @media #{$toDesktop} {
        font-weight: bolder;
       }
    }
    form {
      @media #{$toDesktop} {
        width: 50%;;
       }
    }
  }
  }