%container {
  width: 90%;
  margin: auto;
  @media #{$toDesktop} {
    width: 100%;
  }
}
%meetingBtn {
  border: 1px solid $maincolor;
  background-color: white;
  outline: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}
%button {
  width: 100% !important;
  padding: 15px;
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 10px;
  outline: none;
  color: white !important;
  font-weight: bold;
  background-color: #1376b6;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  &:disabled {
    background-color: $disabledmaincolor;
    color: white;
  }
  svg {
    font-size: 1.3rem;
    font-weight: bold;
  }
  @media #{$toDesktop} {
    width: fit-content;
  }
}
%input {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 0.5px solid rgb(219, 219, 219);
  margin-top: 5px;
  margin-bottom: 10px;
  outline: none;
  @media #{$toDesktop} {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
%formSection {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
  label {
    text-align: start;
    color: $imperial;
    font-weight: bold;
  }
  select {
    @extend %select;
  }
  input[type="date"] {
    padding: 15px;
  }
  input[type="datetime-local"] {
    padding: 15px;
  }
}
%form {
  display: flex;
  flex-direction: column;
  .inputWrapper {
    width: 100%;
    .inputContent {
      border-radius: 5px;
      position: relative;
      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        color: $maincolor;
      }
    }
  }
  #forgot {
    width: 100%;
    text-align: right;
    a {
      text-decoration: none;
      color: $maincolor;
      font-weight: bold;
    }
  }
  input {
    box-sizing: border-box;
    @extend %input;
  }
  input[type="submit"] {
    box-sizing: border-box;
    @extend %button;
  }
}

%ulMenu {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-inline-start: 0px;
  h2 {
    padding-inline-start: 20px;
    color: $imperial;
    font-size: 1rem;
  }
  li {
    padding: 10px;
    color: $natural;
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: $lightmaincolor;
    }
    svg {
      font-size: 1.3rem;
    }
  }
}
%anchor {
  text-decoration: none;
  width: fit-content;
  color: $maincolor;
  display: block;
  margin: 15px auto;
  background-color: transparent;
  border: none;
  outline: none;
}
%select {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding: 15px;
  padding-right: 2rem !important;
  border-radius: 3px;
}

%scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $maincolor;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $imperial;
  }
}
