.Date {
  background-color: #444b53 !important;
  width: 50px;
  height: 53px;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  .month  , .day{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .month{
    flex: 1;
    span{
        color: white;
        font-weight: bold;
        font-size: 0.7rem;
    }
  }
  .day{
    flex: 2;
    padding: 3px;
    box-sizing: border-box;
    .wrapper{
        background-color: white;
        width: 100%;
        border-radius: 0px 0px 3px 3px;
        display: flex;
        span{
            color: #444b53;
            display: block;
            margin: auto;
            font-weight: bold;
            font-size: 1.4rem;
        }
    }
  }
}
