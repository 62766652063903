.mapview {
  @media #{$Tablet} {
    text-align: start !important;
    margin-bottom: 30px;
  }
  .guiding {
    @media #{$miniDesktop} {
      margin-top: 50px;
    }
  }
}
.floorplan-container {
  @media #{$Tablet} {
    overflow: auto;
    box-sizing: border-box;
    height: fit-content !important;
  }
  .floorplan-content {
    .floorplan-plan {
      @media #{$Tablet} {
        transform: rotate(0deg) !important;
      }
    }
  }
  @media #{$miniDesktop} {
    display: block !important;
    border: unset !important;
    height: unset !important;
  }
  @extend %scrollbar;
  img {
    width: 100%;
  }
}
