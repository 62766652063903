@import "../../../sass/variabels", "../../../sass/components";
header {
  background-color: $lightmaincolor;
  display: flex;
  height: 46px;
  box-sizing: border-box;
  position: relative;
  .burgerMenu,
  .info {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $maincolor;
  }
  .info {
    gap: 15px;
    font-size: 1.5rem;
    div {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;
      padding: 0px 5px;
    }
    .notificationHead {
      position: relative;
      .notificationNumber {
        position: absolute;
        top: 15%;
        right: 25%;
        background-color: red;
        color: white;
        padding: 5px;
        font-weight: bold;
        border-radius: 10px;
        font-size: 0.5rem;
      }
    }
    .profile {
      p {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $maincolor;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 0.5rem;
        line-height: 20px;
      }
    }
  }
  .burgerMenu {
    font-size: 1.5rem;
    cursor: pointer;
    span {
      font-size: 0.8rem;
    }
  }
  .logo {
    height: 100%;
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 75px;
    }
  }
  div[role="input"] {
    margin: 0px 10px;
    padding: 15px;
    border: 1px solid #b3b3b3;
    background-color: white;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    #arrow {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%) rotateX(0deg);
      transition: all 0.5s ease-in-out;
      color: #666666;
    }
    ul {
      position: absolute;
      top: 100%;
      z-index: 20;
      background-color: white;
      width: 100%;
      left: -1px;
      border-right: 1px solid #b3b3b3;
      border-left: 1px solid #b3b3b3;
      border-bottom: 1px solid #b3b3b3;
      display: none;
    }
    .emojiFlag {
      font-size: 1.5rem !important;
      margin-inline-end: 12px;
    }
    .lang {
      color: 666666;
      font-size: 1rem;
      font-weight: 300;
      text-align: center;
      line-height: 100%;
    }
  }
  .slideDown {
    position: fixed;
    left: 0;
    top: 46px;
    background-color: $sidedown;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1000;
    -webkit-box-shadow: 10px 10px 274px -41px rgba(222, 222, 222, 1);
    -moz-box-shadow: 10px 10px 274px -41px rgba(222, 222, 222, 1);
    box-shadow: 10px 10px 274px -41px rgba(222, 222, 222, 1);
    button {
      width: fit-content;
      text-align: center;
      font-size: 1rem;
      color: $maincolor;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      align-self: flex-end;
      font-weight: 700;
      margin: 5px;
    }
    ul {
      @extend %ulMenu;
    }
  }
}
.active {
  background-color: $sidedown !important;
}
.Screenheight {
  height: calc(100vh - 46px) !important;
  overflow-y: auto; 
}
@import "./ToDesktop";
