main {
  .wrapper {
    .content {
      @media #{$Tablet} {
        padding-inline-start: 60px;
      }
      h2 {
        @media #{$Tablet} {
          text-align: start;
        }
      }
      .Meetings-widgets {
        @media #{$Tablet} {
          display: grid;
          grid-template-columns: repeat(2,50%);
          gap: 10px;
        }
      }
    }
  }
}
