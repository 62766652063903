@import "../../../../../sass/variabels";
.notificationBoss {
  display: flex;
  flex-direction: column;
  gap: 0px;
  .notify {
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .icon {
      flex: 1;
      font-size: 3.5rem;
    }
    .message {
      flex: 3;
    }
    .action_button {
      padding: 5px;
      color: white;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
    .accept {
      background-color: rgb(0, 141, 0);
    }
    .decline {
      background-color: rgb(165, 0, 0);
    }
    .emphasis {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: inherit;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .time{
      color: gray;
      font-size: 0.7rem;
      width: 100%;
    }
  }
  .unread {
    background-color: $lightmaincolor;
  }
  .read {
    background-color: $sidedown;
  }
}
