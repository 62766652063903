@import "../../../sass/variabels" , "../../../sass/components";
aside {
  height: 100%;
  background-color: $lightmaincolor;
  position: fixed;
  width: 56px;
  display: none;
  transition: all 0.5s ease-in-out;
  z-index: 4;
  .collapse {
    display: flex;
    justify-content: end;
    align-items: center;
    button {
      color: $natural;
      width: 100px;
      height: 50px;
      background-color: transparent;
      border: none;
      outline: none;
      transition: all 0.1s ease-in-out;
      transform: rotateY(180deg);
      display: flex;
      gap: 10px;
      cursor: pointer;
      img {
        width: 20px;
      }
    }
  }
  .menu{
    animation: opacityAnimation 1s ease-in-out;
    ul{
        @extend %ulMenu;
    }
  }
}
.w-250 {
  width: 250px !important;
}
.rotate {
  transform: rotateY(0deg) !important;
}
.w-20p{
    width: 20% !important;
}
@keyframes opacityAnimation {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
@import "./ToDesktop";
