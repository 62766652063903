.confirmation-container {
  @media #{$Tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    background-color: rgba($color: #000000, $alpha: 0.5);
    height: calc(100vh - 60px);
    top: 60px;
    left: 0;
    width: 100%;
  }
  .confirmation {
    @media #{$Tablet} {
      width: fit-content;
      margin: auto;
      position: static;
      top: unset;
      left: unset;
      max-height: 870px !important;
      height: unset;
      min-width: 450px;
    }
  }
}
