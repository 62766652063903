p {
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  overflow: hidden;
}
.fl-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.fl-col-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.fl-col-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.fl-row-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.fl-row-start {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.fl-row-end {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.mirror {
  background-color: transparent;
  border: none;
  outline: none;
}
main {
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
}
.p-rel {
  position: relative;
}
.no-mx-height {
  max-height: unset !important;
}
