.Meeting-container {
  .Meeting-information {
    @media #{$miniDesktop} {
      display: flex;
    }
    .information {
      @media #{$miniDesktop} {
        flex: 2;
        justify-content: space-around;
        padding-inline-start: 15px;
        padding-inline-end: 15px;
      }
      p {
        @media #{$miniDesktop} {
          font-size: 1rem;
        }
      }
    }
  }
}
