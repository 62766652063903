@import "../../../sass/variabels";
.search-wrapper {
  display: flex;
  height: calc(100vh - 76px);
  box-sizing: border-box;
  gap: 10px;
  .search-form,
  .search-select {
    flex: 1;
    background-color: $secondcolor;
  }
  .search-select {
    box-sizing: border-box;
    padding: 5px;
    .nothing {
      text-align: center;
      color: $imperial;
    }
  }
  .search-result {
    flex: 3;
    box-sizing: border-box;
    border: 1px solid $imperial;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 20px;
    max-height: calc(100vh - 76px);
    @extend %scrollbar;
    .head {
      display: flex;
      .floorplan-actions {
        flex: 2;
      }
      .tabs {
        flex: 1;
      }
    }
  }
}
.floorplan-btns {
  button {
    padding: 10px;
    border-radius: 3px;
    border: 1px solid $maincolor;
    background-color: white;
    outline: none;
    cursor: pointer;
    color: $maincolor;
    svg {
      font-size: 1.5rem;
      color: $maincolor;
    }
    &:hover {
      background-color: $maincolor;
      color: white;
      svg {
        color: white;
      }
    }
  }
}
.expanded {
  @extend %scrollbar;
}
@import "../../../components/Confirmation/styling/Confirmation.scss";
@import "../../../components/Expanded/styling/Expanded.scss";
@import "./ToDesktop";
